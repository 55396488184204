<template>
  <div :class="`travel-scale color--${color}`" :title="tooltips"></div>
</template>
  
<script lang="ts" setup>
import { TravelRating } from '@/lib/types/models/weather';

const { travelRatingForWeather } = defineProps<{
  travelRatingForWeather: TravelRating;
}>();
const color = ref<'red' | 'orange' | 'lime' | 'yellow' | 'green' | undefined>(undefined);
const tooltips = ref<
  | 'Période déconseillée pour voyager'
  | 'Pas la meilleure période pour voyager'
  | 'Periode idéale pour voyager'
  | undefined
>(undefined);

switch (travelRatingForWeather) {
  case 1:
    color.value = 'red';
    tooltips.value = 'Période déconseillée pour voyager';
    break;
  case 2:
    color.value = 'orange';
    tooltips.value = 'Pas la meilleure période pour voyager';
    break;
  case 3:
    color.value = 'yellow';
    tooltips.value = 'Pas la meilleure période pour voyager';
    break;
  case 4:
    color.value = 'lime';
    tooltips.value = 'Periode idéale pour voyager';
    break;
  case 5:
    color.value = 'green';
    tooltips.value = 'Periode idéale pour voyager';
    break;
  default:
    color.value = undefined;
}
</script>
  
<style lang="scss" scoped>
@use '$/colors.scss';

.travel-scale {
  width: 100%;
  height: 5px;
  border-radius: 5px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.color {
  &--red {
    background-color: colors.$travel-scale-1;
  }

  &--orange {
    background-color: colors.$travel-scale-2;
  }

  &--yellow {
    background-color: colors.$travel-scale-3;
  }

  &--lime {
    background-color: colors.$travel-scale-4;
  }

  &--green {
    background-color: colors.$travel-scale-5;
  }
}
</style>

  