<template>
  <Icon class="icon" :name="`weather-${iconWeather}`" />
</template>

<script lang="ts" setup>
const { rainScaleWeather } = defineProps<{
  rainScaleWeather?: number;
}>();
const iconWeather = ref<'rain' | 'sun' | 'partial-clouds' | undefined>(undefined);

iconWeather.value = 'rain';

if (rainScaleWeather !== undefined && rainScaleWeather < 15) {
  iconWeather.value = 'sun';
} else if (rainScaleWeather !== undefined && rainScaleWeather >= 15 && rainScaleWeather <= 17) {
  iconWeather.value = 'partial-clouds';
}
</script>


    

  
    