<template>
  <div v-if="weatherRegion" class="wrapper">
    <SectionTitle v-if="country" class="wrapper__title"
      >La meilleure période pour partir
      {{ `${route.params.countryid ? country.preposition : 'en'} `
      }}{{
        route.params.countryid
          ? country.name
          : weathersRegions[0]?.attributes.country?.data.attributes.continents?.data[0]?.attributes
              .name
      }}</SectionTitle
    >
    <SectionTitle v-else class="wrapper__title"
      >La meilleure période pour partir dans cette région</SectionTitle
    >
    <RegionsSelector
      v-if="isRegionsSelector"
      v-model:selectedRegion="weatherRegion"
      class="wrapper__selector"
      :regions="weathersRegions"
    />

    <ReadMore
      :key="weatherRegion?.id"
      class="wrapper__description"
      :content="weatherRegion?.attributes.description ?? ''"
      :max-lines="3"
    />
    <YearlyWeather
      class="wrapper__weather"
      :description="weatherRegion?.attributes.description ?? ''"
      :weathers="weatherRegion?.attributes.monthWeather"
    />
    <div v-if="weatherRegion?.attributes.flightInfos" class="wrapper-filght-info"></div>
    <FlightInfos :regionflight-infos="weatherRegion.attributes.flightInfos" />
    <div class="when-container">
      <NuxtLink
        v-if="!route.params.country"
        :href="`/${route.params.continentid}/${route.params.countryid}/infos-pratiques/ou-et-quand-partir-${country?.preposition}-${country?.slug}/`"
      >
        <button class="when">Savoir quand partir</button></NuxtLink
      >
    </div>
  </div>
</template>
<script lang="ts" setup>
import { MvRegion } from '@/lib/strapi-types/MvRegion';
import YearlyWeather from './Weather/YearlyWeather.vue';
import { ContextDictionary } from '@/lib/types/models/header';
import FlightInfos from './FlightInfos.vue';
const {
  country,
  weathersRegions,
  isRegionsSelector = true
} = defineProps<{
  country?: ContextDictionary;
  weathersRegions: MvRegion[];
  isRegionsSelector?: boolean;
}>();

const route = useRoute();
const weatherRegion = ref<MvRegion | undefined>(weathersRegions[0]);
</script>
<style lang='scss' scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/colors.scss';
@use '$/button.scss';

.wrapper {
  flex-direction: column;
  align-items: center;
  align-self: center;

  width: 1040px;
  padding: 20px;

  @include breakpoints.mobile() {
    width: 100%;
  }

  &__weather,
  &__title,
  &__description,
  &__selector {
    width: metrics.$inner-width;

    @include breakpoints.mobile() {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__description {
    min-height: 30px;
    margin: 32px 0;
    font-size: 18px;
  }

  .wrapper-filght-info {
    flex-direction: column;
    width: 100%;
  }

  .when-container {
    justify-content: center;
    margin-top: 40px;

    .when {
      @include button.plain($size: big);
    }
  }
}
</style>

