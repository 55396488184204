<template>
  <div v-if="regionflightInfos" class="wrapper-filght-info">
    <div class="separator" />
    <div class="flight-infos">
      <div v-for="info of flightInfos" :key="info.title" class="info">
        <Icon class="icon" :name="info.icon" />
        <div class="title">{{ info.title }}</div>
        <div class="title mobile">{{ info.titleMobile }}</div>
        <div class="value">{{ info.value }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { RegionFlightInfos } from '@/lib/strapi-types/components/RegionFlightInfos';

const { regionflightInfos } = defineProps<{
  regionflightInfos?: RegionFlightInfos;
}>();

const flightInfos = $computed(() => [
  {
    icon: 'flight',
    title: 'Temps de vol moyen',
    titleMobile: 'Tps de vol',
    value: regionflightInfos?.flightDuration
  },
  {
    icon: 'jetlag',
    title: 'Décalage horaire',
    titleMobile: 'Décal. horaire',
    value: regionflightInfos?.jetLag
  },
  {
    icon: 'flight-visa',
    title: 'Visa',
    titleMobile: 'Visa',
    value: regionflightInfos?.visaRequired
  }
]);
</script>

<style lang='scss' scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/metrics.scss';

.wrapper-filght-info {
  flex-direction: column;
  width: 100%;

  .separator {
    width: 100%;
    margin: 40px 0;
    border-top: 1px solid colors.$grey-500;

    @include breakpoints.mobile() {
      margin: 20px metrics.$mobile-inner-margin;
    }
  }

  .flight-infos {
    gap: 104px;
    justify-content: center;

    @include breakpoints.mobile() {
      gap: 8px;
    }

    .info {
      flex-direction: column;
      gap: 12px;
      align-items: center;
      width: 161px;

      @include breakpoints.mobile() {
        gap: 8px;
        width: 104px;
      }

      .icon {
        height: 44px;

        @include breakpoints.mobile() {
          transform: scale(0.75);
          height: 24px;
        }
      }

      .title {
        font-size: 18px;
        line-height: 26px;

        @include breakpoints.mobile() {
          display: none;
          font-size: 16px;
          line-height: 24px;
        }

        &.mobile {
          display: none;

          @include breakpoints.mobile() {
            display: flex;
          }
        }
      }

      .value {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        text-align: center;

        @include breakpoints.mobile() {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
